<template>
  <BlokkliProvider v-bind="blokkliProps" :entity="props">
    <MediaImage v-if="hero" v-bind="hero" />
    <DrupalLink v-if="link && linkPath" :to="linkPath">{{
      link.title
    }}</DrupalLink>

    <BlokkliField name="field_paragraphs" :list="paragraphs" />

    <slot />
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import type { NodePageFragment, MediaImageFragment } from '#graphql-operations'

const props = defineProps<{
  title?: string
  nid?: string
  uuid: string
  canEdit?: boolean
  subtitle?: string
  body?: string
  hero?: MediaImageFragment
  link?: NodePageFragment['link']
  paragraphs?: NodePageFragment['paragraphs']
  blokkliProps: NodePageFragment['blokkliProps']
}>()

const linkPath = computed(() => props.link?.uri?.path)
</script>
